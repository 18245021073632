import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { map, mergeMap } from 'rxjs/operators';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../../app-take-survey/consts/resources.consts';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SkipInterceptor } from '../consts/interceptors.const';
import { SharedService } from './shared.service';
import { SurveysProvider } from '../../app-admin/providers/surveys.provider';
import { StorageProvider } from '../providers/storage.provider';

@Injectable()
export class ApplicationLanguageService {
    public langs: string[] = ['en', 'fr', 'ru'];
    public defaultLang = 'en';
    currentLanguage = 'en-EN';
    supportedLanguages = { en: 'en-US', fr: 'fr-CA', ru: 'ru-RU' };
    private renderer: Renderer2;
    defaultSettingsSet: boolean;

    constructor(
        private translateService: TranslateService,
        private surveyProvider: SurveysProvider,
        private sharedService: SharedService,
        private storageProvider: StorageProvider,
        private rendererFactory: RendererFactory2,
        private $http: HttpClient
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }


    initLanguageSettings(): Observable<boolean> {
        this.setDefaultSettings();
        return this.getContactLanguage().pipe(
            mergeMap(lang => {
                return forkJoin([
                    this.updateApplicationText(lang),
                    this.translateService.use(lang)
                ]);
            }),
            map(([data]) => {
                const updatedText = { ...this.sharedService.surveyText.value, ..._.pickBy(data) };
                this.sharedService.surveyText.next(updatedText);
                return true;
            })
        );
    }

    setDefaultSettings() {
        if (!this.defaultSettingsSet) {
            this.translateService.addLangs(this.langs);
            this.translateService.setDefaultLang(this.defaultLang);
            this.subscribeToLanguageUpdates();
            this.defaultSettingsSet = true;
        }

    }

    getContactLanguage() {
        return this.storageProvider.validateIdentity().pipe(
            mergeMap((status) => {
                if (status) {
                    return this.getCurrentContactLanguage();
                } else {
                    return of(this.defaultLang);
                }
            }),
            map(userLanguage => {
                const language = _.find(
                    this.langs,
                    lang => userLanguage.indexOf(lang) > -1
                );
                return language || this.defaultLang;
            })
        );
    }

    subscribeToLanguageUpdates() {
        this.sharedService.getData('currentLanguage').subscribe(data => {
                this.renderer.removeClass(document.body, this.currentLanguage);
                this.currentLanguage = data.data;
                this.renderer.addClass(document.body, this.currentLanguage);
            }
        );
    }

    updateApplicationText(lang) {
        const internationalLanguage = this.supportedLanguages[lang];
        return this.surveyProvider.getSurveyTextApplicationSettings(internationalLanguage);
    }

    getCurrentContactLanguage() {
        const url: string = String.Format(ResourcesConsts.CONTACT_LANGUAGE);
        return this.$http
            .get<string>(url)
            .pipe(
                map(val => val || 'en-US')
            );
    }

}
